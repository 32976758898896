import { useContext } from "react";

import { UserContext } from "./Context";
import Login from "./Login";
import './App.css';
import ScanOverview from './ScanOverview'
import Wifi from "./Wifi";

import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";

function App() {
  const token = useContext(UserContext)?.token;

  return (
    <Router>

    <div className="App">
      {!token ? (
            <div className="columns">
              <Login />
            </div>
          ) : (


            <Switch>
            <Route path="/wifi">
              <Wifi />
            </Route>
            <Route path="/">
              <ScanOverview/>
            </Route>
          </Switch>

          //   <p>        <button className="button" onClick={handleLogout}>
          //   Logout
          // </button></p>
          )}


    </div>
    </Router>

  );
}

export default App;
