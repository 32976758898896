import { useState, useEffect, useContext} from 'react';
import AppBar from '@mui/material/AppBar';
import CameraIcon from '@mui/icons-material/PhotoCamera';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Switch from '@mui/material/Switch';

import Settings from './Settings';
import { UserContext } from "./Context";

type WifiState = {
  [short_name: string]: {
    name: string,
    enabled: boolean,
    updating: boolean
  }
}

export default function Wifi() {

  const token = useContext(UserContext)?.token;
  const [wifi, setWifi] = useState<WifiState>({});

  const changeWifi = (wifiKey:string, desiredState:boolean) => {
    fetch(
      Settings.base + `/wlan`,
      {
        method: "POST",
        body: JSON.stringify( {wifi_name: wifiKey, wifi_enable: desiredState} ),
        headers: new Headers({
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + token
        })
      }
    )
      .then(res => res.json())
      .then(response => {
        // console.log(response);
        setWifi(response)
      })
      .catch(error => console.log(error));

  };

  useEffect(() => {
    const getWifi =  () => {
      fetch(
        Settings.base + `/wlan`,
        {
          method: "GET",
          headers: new Headers({
            Accept: "application/json",
            Authorization: "Bearer " + token
          })
        }
      )
        .then(res => res.json())
        .then(response => {
          // console.log(response);
          setWifi(response)
        })
        .catch(error => console.log(error));
    }


    getWifi();
    const interval=setInterval(()=>{
      getWifi()
     },1000)
     return()=>clearInterval(interval)

  }, [token]); // Only re-run the effect if count changes

  return (
    <div>
      <AppBar position="relative">
        <Toolbar>
          <CameraIcon sx={{ mr: 2 }} />
          <Typography variant="h6" color="inherit" noWrap>
            WLAN
          </Typography>
        </Toolbar>
      </AppBar>
      <main>
        <div>
          {(Object.keys(wifi)).sort().map( key =>
            <div key={key}>{wifi[key].name}
              <Switch
                checked={wifi[key].enabled}
                disabled={wifi[key].updating}
                onClick={() => changeWifi(key, !wifi[key].enabled)}
                />
            </div>
            // <div key={key}>{wifi[key].name}: {String(wifi[key].enabled)}</div>
          )}
        </div>
      </main>
      {/* Footer */}

      {/* End footer */}
      </div>
  );
}