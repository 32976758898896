import React, { createContext, useEffect, useState, ReactNodeArray, ReactNode } from "react";
import Settings from "./Settings";

type Value = {
  token?: string,
  setToken?: React.Dispatch<React.SetStateAction<string | null>>,
}

export const UserContext = createContext<Value>({});

interface Props {
  children: ReactNode | ReactNodeArray;
}

export const UserProvider = (props: Props) => {
  const [token, setToken] = useState<string | null>(localStorage.getItem("access_token"));

  useEffect(() => {
    const fetchUser = async () => {
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      };

      const response = await fetch(Settings.base +  "/user", requestOptions);

      console.log("after fetch /user", response, token)
      if (!response.ok) {
        setToken(null);
      }
      if (token) {
        localStorage.setItem("access_token", token);
      }
    };
    fetchUser();
  }, [token]);

  const myToken = token || undefined
  return (
    <UserContext.Provider value={{token:myToken, setToken}}>
      {props.children}
    </UserContext.Provider>
  );
};