import { useState, useEffect, useContext} from 'react';
import AppBar from '@mui/material/AppBar';
import Button from '@mui/material/Button';
import CameraIcon from '@mui/icons-material/PhotoCamera';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Container } from '@mui/material';

import Settings from './Settings';
import { UserContext } from "./Context";

type Pages = {
    pages:
      {
        batch: string,
        name: string,
        of: number,
        page: number
      }[]
  }



const cards = [1, 2, 3, 4, 5, 6, 7, 8, 9];

const theme = createTheme();

export default function ScanOverview() {

  const [count, setCount] = useState<number>(0);
  const [pages, setPages] = useState<Pages>({ pages:[]} );
  const token = useContext(UserContext)?.token;

  useEffect(() => {

    fetch(
      Settings.base + `/pagelist`,
      {
        method: "GET",
        headers: new Headers({
          Accept: "application/json",
          Authorization: "Bearer " + token
        })
      }
    )
      .then(res => res.json())
      .then(response => {
        console.log(response);
        setPages(response)
      })
      .catch(error => console.log(error));

  }, [token]); // Only re-run the effect if count changes

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <AppBar position="relative">
        <Toolbar>
          <CameraIcon sx={{ mr: 2 }} />
          <Typography variant="h6" color="inherit" noWrap>
            Scan App
          </Typography>
        </Toolbar>
      </AppBar>
      <main>
        <Container maxWidth="xl" sx={{ display: "flex", flexDirection: "row", alignContent: "center", justifyContent:"center" }}>
          <Grid item container spacing={1} paddingTop={2} xs={12} columnSpacing={{ xs: 1, sm: 1, md: 1 }} >
            {pages.pages && pages.pages.map((page) => (
              <Grid item key={page.name} sm={4} md={2} >
                <Card onClick={ (e) => setCount(count+1)} variant="outlined"
                  sx={{ height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', alignContent: 'center', border: "2px dotted darkgray", background: "lightgray" }}
                >
                  <CardMedia
                    component="img"
                    sx={{
                        maxWidth: 220,
                      // 16:9
                      pt: 2,
                    }}
                    image="https://source.unsplash.com/random"
                    alt="random"
                  />
                  <CardContent sx={{ flexGrow: 1}}>
                    <Typography gutterBottom variant="h6" component="h2">
                      Page
                    </Typography>
                    <Typography>
                      This is a media card. You can use this section to describe the
                      content.
                    </Typography>
                  </CardContent>
                  <CardActions>
                    <Button size="small">View</Button>
                    <Button size="small">Edit</Button>
                  </CardActions>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Container>
      </main>
      {/* Footer */}
      <Box sx={{ bgcolor: 'background.paper', p: 6 }} component="footer">
        <Typography variant="h6" align="center" gutterBottom>
        </Typography>
        <Typography
          variant="subtitle1"
          align="center"
          color="text.secondary"
          component="p"
        >
        </Typography>
      </Box>
      {/* End footer */}
    </ThemeProvider>
  );
}